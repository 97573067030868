import { LuExternalLink } from 'react-icons/lu'

type props = {
  name: string
  url: string
}

const ExternalLink = (props: props) => {
  return (
    <a
      className='text-blue-800 flex flex-tow justify-between items-center hover:underline'
      target='blank'
      href={`${props.url}`}
    >
      {props.name}
      <LuExternalLink className='ml-0.5' color='#1e3a8a' size={14} />
    </a>
  )
}

export default ExternalLink
