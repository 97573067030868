import Education from '../components/Education'
import Experience from '../components/Experience'
import Header from '../components/Header'
import Skills from '../components/Skills'
import Summary from '../components/Summary'

const EnCvPage = () => {
  return (
    <div className='h-screen w-screen flex flex-col justify-start items-center py-6 bg-white overflow-y-auto'>
      <div className='h-auto sm:w-full lg:w-3/5 2xl:w-5/12 bg-white py-12 px-16'>
        <Header />
        <Summary />
        <Skills />
        <Experience />
        <Education />
      </div>
    </div>
  )
}

export default EnCvPage
