const Skills = () => {
  return (
    <div className='flex flex-col justify-start items-start h-auto w-full mb-12'>
      <h2 className='h-auto w-full text-xl font-bold'>НАВЫКИ</h2>
      <p className='text-lg'>
        <span className='mr-2 text-gray-700'>
          <span className='font-semibold text-base text-gray-800'>
            Языки программирования:{' '}
          </span>
          C#, SQL, TypeScript;
        </span>

        <span className='mr-2 text-gray-700'>
          <span className='font-semibold text-base text-gray-800'>
            Технологии:{' '}
          </span>
          ASP.NET Core, Git, EF Core, Docker, React, ASP.NET MVC, XUnit;
        </span>

        <span className='mr-2 text-gray-700'>
          <span className='font-semibold text-base text-gray-800'>СУБД: </span>
          PostgreSQL, MSSQL Server;
        </span>

        <span className='mr-2 text-gray-700'>
          <span className='font-semibold text-base text-gray-800'>
            Тестирование API:{' '}
          </span>
          Swagger, Postman;
        </span>

        <span className='mr-2 text-gray-700'>
          <span className='font-semibold text-base text-gray-800'>ОС: </span>
          Windows, Linux;
        </span>

        <span className='mr-2 text-gray-700'>
          <span className='font-semibold text-base text-gray-800'>Языки: </span>
          Русский – родной, Английский – B2, Немецкий - A1;
        </span>
      </p>
    </div>
  )
}

export default Skills
