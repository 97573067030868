import { Link } from 'react-router-dom'

const LanguageSwitcher = () => {
  return (
    <div className='p-0.5'>
      <Link className='hover:underline mr-1' to='/resume'>
        RU
      </Link>
      <Link className='hover:underline mr-1' to='/cv'>
        EN
      </Link>
    </div>
  )
}

export default LanguageSwitcher
