const Education = () => {
  return (
    <div className='flex flex-col justify-start items-start h-auto w-full'>
      <h2 className='h-auto w-full text-xl font-bold'>ОБРАЗОВАНИЕ</h2>
      <h6 className='h-auto w-full text-md font-bold flex flex-row justify-between items-center mb-0.5'>
        <span className='text-gray-800'>Высший Колледж Информатики НГУ</span>
        <span className='font-normal text-gray-700'>2022-2025</span>
      </h6>
      <p className='text-lg text-gray-700'>
        Специальность – Информационные системы и программирование, 3 курс.
      </p>
    </div>
  )
}

export default Education
