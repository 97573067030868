const Summary = () => {
  return (
    <div className='flex flex-col justify-start items-start h-auto w-full mb-12'>
      <h2 className='h-auto w-full text-xl font-bold'>SUMMARY</h2>
      <p className='text-lg text-gray-700'>
        I have experience in designing RESTful systems and writing test code,
        following the principles of SOLID and various design patterns. I can
        create complex commands both in pure SQL and using LINQ. I understand
        the principles of development taking into testing. I have a good command
        of spoken English and can freely read technical documentation. I strive
        for continuous development in the field of development. Profiled
        education in the field of information technology.
      </p>
    </div>
  )
}

export default Summary
