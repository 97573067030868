import { createBrowserRouter } from 'react-router-dom'
import App from '../App'
import RuResumePage from '../ru/pages/RuResumePage'
import EnCvPage from '../en/pages/EnCvPage'

export const Router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/resume',
        element: <RuResumePage />
      },
      {
        path: '/cv',
        element: <EnCvPage />
      }
    ]
  },
  {
    path: '*',
    element: <h1 className='text-xl font-bold p-3'>Page not found</h1>
  }
])
