import ExternalLink from '../../components/ExternalLink'
import LanguageSwitcher from '../../components/LanguageSwitcher'

const Header = () => {
  return (
    <div className='h-auto w-full flex flex-col justify-start items-center mb-12'>
      <div className='h-auto w-full flex flex-row justify-between items-center'>
        <h1 className='text-3xl font-bold'>Тимур Салахутдинов</h1>
        <LanguageSwitcher />
      </div>
      <div className='h-auto w-full flex flex-row justify-start items-center text-lg text-nowrap'>
        <p>Новосибирск, Академгородок</p>
        <span className='px-1'>|</span>
        <ExternalLink name='Telegram' url='https://t.me/TimSalakh' />
        <span className='px-1'>|</span>
        <ExternalLink name='GitHub' url='https://github.com/TimSalakh' />
      </div>
    </div>
  )
}

export default Header
