const Skills = () => {
  return (
    <div className='flex flex-col justify-start items-start h-auto w-full mb-12'>
      <h2 className='h-auto w-full text-xl font-bold'>SKILLS</h2>
      <p className='text-lg'>
        <span className='mr-2 text-gray-700'>
          <span className='font-semibold text-base text-gray-800'>
            Programming languages:{' '}
          </span>
          C#, SQL, TypeScript;
        </span>

        <span className='mr-2 text-gray-700'>
          <span className='font-semibold text-base text-gray-800'>
            Technologies:{' '}
          </span>
          ASP.NET Core, Git, EF Core, Docker, React, ASP.NET MVC, XUnit;
        </span>

        <span className='mr-2 text-gray-700'>
          <span className='font-semibold text-base text-gray-800'>DBMS: </span>
          PostgreSQL, MSSQL Server;
        </span>

        <span className='mr-2 text-gray-700'>
          <span className='font-semibold text-base text-gray-800'>
            API Testing:{' '}
          </span>
          Swagger, Postman;
        </span>

        <span className='mr-2 text-gray-700'>
          <span className='font-semibold text-base text-gray-800'>OS: </span>
          Windows, Linux;
        </span>

        <span className='mr-2 text-gray-700'>
          <span className='font-semibold text-base text-gray-800'>
            Languages:{' '}
          </span>
          Russian - native, English – B2, German - A1;
        </span>
      </p>
    </div>
  )
}

export default Skills
